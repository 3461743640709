import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import React from 'react';

export default function RichText({ data }) {
    const getEmbeddedAssets = (content) => {
        const assets = []
        if (content) {
            for (const item of content) {
                item.nodeType === 'embedded-asset-block' ? assets.push(item) : null
              }
              return assets
        } else {
            return null
        }
    }

    function renderOptions(assets) {
        const assetMap = new Map()
        if (assets) {
            for (const asset of assets) {
                assetMap.set(asset.data.target.sys.id, asset)
            }
        }
        return {
            renderText: (text) => {
                return text.split('\n').reduce((children, textSegment, index) => {
                  return [...children, index > 0 && <><br key={index} /><br/></>, textSegment];
                }, []);
            },
            renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
                    const asset = assetMap.get(node.data.target.sys.id)
                    let src 
                    if (asset) {
                        src = {url: asset?.data?.target?.fields?.file?.url, alt: asset?.data?.target?.fields?.description}
                        return (
                            <img style={{width: data?.assetSize, margin: 'auto', display: 'block'}} src={src.url} alt={src.alt} />
                        )
                    } else {
                        return null
                    }
                },
                [INLINES.HYPERLINK]: (node, next) => {
                    return (
                        <a href={node?.data?.uri} target={'_blank'} title={node?.data?.uri} rel={'noopener noreferrer'} style={{textDecoration: 'none', color: '#E42127'}}>{node?.content[0]?.value}</a>
                    )
                },
            },
        }
    }

    return (
        documentToReactComponents(data.richTextData, renderOptions(getEmbeddedAssets(data.content)))
    )   
}