import React from 'react'
import { graphql } from 'gatsby'
import '../styles/text-page-styles.css'
import '../styles/resident-verify.css' // specifically for the table in /leasing/resident-verify/terms-and-conditions
import ReactMarkdown from 'react-markdown'
import RichText from './rich-text'
import remarkGfm from 'remark-gfm'
import remarkRehype from 'remark-rehype'
import rehypeRaw from 'rehype-raw'
import { symbolResize } from '../helper-functions'

export default function LegalSection({ layoutPiece }) {
  const content = layoutPiece?.legalSectionContent 

  const data = {
    richTextData: content?.raw ? JSON.parse(content.raw) : null,
    assets: content?.references,
    assetSize: '100%' // CSS width property
  }

  return (
    <div className="privacy-policy legal-section-spacing">
      <div className="content-cap">
        <div className="legal-content legal-section-spacing">
          <h2 id={layoutPiece?.indexId} className="indexed">{symbolResize(layoutPiece?.legalSectionTitle)}</h2>
          {
            layoutPiece?.legalSectionText ? 
              <ReactMarkdown remarkPlugins={[remarkGfm, remarkRehype]} rehypePlugins={[rehypeRaw]} className="sub-text">{layoutPiece?.legalSectionText?.legalSectionText}</ReactMarkdown>
            : ""
          }
          {
            content?.raw || content?.references ?
              <div className='rich-text legal-rich-text'>
                <RichText data={data} />
              </div>
              : ""
          }
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
    fragment LegalPageComponent on ContentfulLegalPageComponent {
        id
        indexId
        legalSectionTitle
        legalSectionContent {
          raw
          references {
            contentful_id
            url
            description
          }
        }
    }
`